import {mapGetters, mapActions, mapState, mapMutations} from 'vuex'
import {findOptions, getSegmentQueryParams} from '@/function'

import {DATE_FORMAT, NOTIFY_STATUS, SEARCH_SYSTEM, CATALOG_ENTITY_TYPE} from '@/const'
import MODAL_TITLE from '@/modals/modalTitle'
import ROUTE_NAME from '@/router/routeName'

import updateUrl from '@/mixins/updateUrl'
import regionFilter from '@/mixins/regionFilter'

import filters from './components/filters'
import container from '@/services/components/container'
import tableRow from './components/tableRow'
import error from '@/services/errors/block'
import noProjects from '@/services/errors/noProjects'
import noData from '@/services/errors/noData'
import tableHeadCell from '@/components/tableHeadCell/tableHeadCell'
import filtersResetButton from '@/services/components/filtersResetButton'
import multiaction from '@/services/components/multiaction'
import sliceBtn from './components/sliceBtn'
import tableHeadCellContainer from '@/components/tableHeadCell/container/tableHeadCellContainer'

const PERIOD_COUNT = ['one', 'two']
const TRAFFIC_FILTER_TYPES = ['source', 'medium']

export default {
    name: 'entityContainer',

    metaInfo () {
        return {
            title: `${this.title.primary} / ${this.title.secondary} / ${this.activeProjectData.label} (${this.activeProjectData.id})`
        }
    },

    mixins: [
      updateUrl,
      regionFilter
    ],

    provide () {
        return {
            tableCellsConfigKey: this.tableCellsConfigKey
        }
    },

    components: {
        'v-filters': filters,
        'v-container': container,
        'v-table-row': tableRow,
        'v-error': error,
        'v-no-projects': noProjects,
        'v-no-data': noData,
        'v-table-head-cell': tableHeadCell,
        'v-filters-reset-button': filtersResetButton,
        'v-multiaction': multiaction,
        'v-slice-btn': sliceBtn,
        'v-table-head-cell-container': tableHeadCellContainer
    },

    props: {
        title: {
            type: Object,
            default: () => ({})
        },

        metricFilters: {
            type: Array,
            default: () => ([])
        },

        metricFiltersQuery: {
            type: Array,
            default: () => ([])
        },

        tags: {
            type: Array
        },

        activeProjectId: {
            type: Number,
            default: undefined
        },

        data: {
            type: Object,
            default: () => ({})
        },

        tableCellsConfig: {
            type: Array,
            default: () => ([])
        },

        page: {
            type: String,
            default: ''
        },

        label: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            preloaderFilters: true,
            preloaderTable: true,
            MODAL_TITLE,
            itemsSelected: [],
            searchEngines: [
                {id: SEARCH_SYSTEM.GOOGLE, name: 'Google'},
                {id: SEARCH_SYSTEM.YANDEX, name: 'Яндекс'}
            ],
            slicedName: true
        }
    },

    computed: {
        ...mapState({
          activeProjectData: state => state.active_project_data,
          summary: state => state.dashboard.summary,
          segmentsCatalogDF: state => state.segments.catalog.dataDF,
          segmentsCustomDF: state => state.segments.catalog.dataDFCustom,
          trafficConditions: state => state.trafficConditions,
          trafficSources: state => state.trafficSources
        }),

        ...mapGetters([
            'filtersDevices',
            'lackOfProjects'
        ]),

        urlFilters () {
            let initFilters = (query, options, required = false, defaultOption, cycle, optionKey = 'id', number = false) => {
                try {
                    if (cycle) {
                        if (query) {
                            if (query && Array.isArray(query)) {
                                return query.map(f => number ? +f : f).filter(f => f !== null && f !== undefined && ((number && !isNaN(f)) || !number))
                            } else if (typeof query === 'string' && number && !isNaN(+query)) {
                                return [+query]
                            } else if ((typeof query === 'number' && !isNaN(+query)) || typeof query === 'string' && !number) {
                                return [query]
                            } else {
                                return defaultOption || []
                            }
                        } else {
                            return defaultOption || []
                        }
                    } else {
                        if (typeof query === 'object' && options) {
                            for (let item of options) {
                                if (query.source && query.source === item.source && query.medium && query.medium === item.medium) {
                                    return item.id
                                }
                            }
                        } else {
                            if (typeof query === 'number' && (query || query === 0) && findOptions(query, options, optionKey)) {
                                return +query
                            } else if (query && findOptions(query, options, optionKey)) {
                                return query
                            } else if (required) {
                                return defaultOption || options[0].id
                            } else {
                                return null
                            }
                        }
                    }
                } catch (_) {
                    return null
                }
            }

            let queryParams = this.$route.query
            let filters = {
                date: {
                    one: {
                        from: undefined,
                        to: undefined
                    },
                    two: {
                        from: undefined,
                        to: undefined
                    }
                }
            }

            filters.limit = (+queryParams.limit <= 100 && queryParams.limit) || 50
            filters.limit = filters.limit > 100 ? 100 : filters.limit

            filters.page = +queryParams.page || 1

            filters.region_id = initFilters(queryParams.region_id, this.regions, false, undefined, true, 'google_region_id', true)

            filters.is_mobile = initFilters(+queryParams.is_mobile, this.devices)

            filters.types = this.pageListing
                ? initFilters(queryParams.types, this.tags, false, [CATALOG_ENTITY_TYPE.LISTING, CATALOG_ENTITY_TYPE.TAG_LISTING], true)
                : [CATALOG_ENTITY_TYPE.OFFER]

            filters.catalog_ids = initFilters(queryParams.catalog_ids, this.allTreeSegments.items, false, [], true).filter(id => !isNaN(+id))

            this.metricFiltersQuery.forEach(filter => {
                filters[filter] = queryParams[filter]
            })

            filters.order_by = queryParams.order_by

            filters.order_direction = queryParams.order_direction

            filters[this.searchEntityParamName] = queryParams[this.searchEntityParamName]

            filters.search_engine = initFilters(queryParams.search_engine, this.searchEngines)

          filters.traffic_source = initFilters(queryParams.traffic_source, this.trafficSources, true, 'ga4')

            for (let type of TRAFFIC_FILTER_TYPES) {
                filters[type] = initFilters(queryParams[type], undefined, false, [], true)
                filters[`traffic_conditions[${type}]`] = initFilters(queryParams[`traffic_conditions[${type}]`], this.trafficConditions)
            }

            for (let count of PERIOD_COUNT) {
                if (queryParams[`period_${count}_date_from`] && queryParams[`period_${count}_date_to`]) {
                    let dateFrom = +this.$moment.utc(queryParams[`period_${count}_date_from`], DATE_FORMAT.BASE_FORMAT).format('x')
                    let dateTo = +this.$moment.utc(queryParams[`period_${count}_date_to`], DATE_FORMAT.BASE_FORMAT).format('x')

                    filters.date[count] = {
                        from: dateFrom || dateTo,
                        to: dateTo || dateFrom
                    }
                } else if (count === 'one') {
                    if (queryParams[`period_${count}_date_to`]) {
                        let dateTo = +this.$moment.utc(queryParams[`period_${count}_date_to`], DATE_FORMAT.BASE_FORMAT).format('x')

                        filters.date[count] = {
                            from: +this.$moment.utc(dateTo, 'x').subtract(7, 'days').format('x'),
                            to: dateTo
                        }
                    } else if (queryParams[`period_${count}_date_from`]) {
                        let dateFrom = +this.$moment.utc(queryParams[`period_${count}_date_from`], DATE_FORMAT.BASE_FORMAT).format('x')
                        let dateTo = +this.$moment.utc(dateFrom, 'x').add(7, 'days').format('x')
                        filters.date[count].from = dateFrom

                        if (dateTo > +this.$moment.utc().endOf('day').format('x')) {
                            filters.date[count].to = +this.$moment.utc().startOf('day').format('x')
                        } else {
                            filters.date[count].to = dateTo
                        }
                    } else {
                        let dateDefault = +this.$moment.utc().subtract(1, 'days').startOf('day').format('x')
                        filters.date[count] = {
                            from: +this.$moment.utc(dateDefault, 'x').subtract(7, 'days').format('x'),
                            to: dateDefault
                        }
                    }
                }
            }

            return filters
        },

        dateFilters () {
            let dateFilters = {
                one: {
                    from: undefined,
                    to: undefined
                },
                two: {
                    from: undefined,
                    to: undefined
                }
            }

            const urlFiltersDate = {...this.urlFilters.date}

            for (let count of PERIOD_COUNT) {
                try {
                    if (urlFiltersDate[count] && urlFiltersDate[count].from) {
                        dateFilters[count].from = this.$moment(urlFiltersDate[count].from).format(DATE_FORMAT.BASE_FORMAT)
                    }

                    if (urlFiltersDate[count] && urlFiltersDate[count].to) {
                        dateFilters[count].to = this.$moment(urlFiltersDate[count].to).format(DATE_FORMAT.BASE_FORMAT)
                    }
                } catch (_) {
                }
            }

            return {
                ...dateFilters,
                comparison: !!dateFilters.two.from && !!dateFilters.two.to && (dateFilters.one.from !== dateFilters.two.from || dateFilters.one.to !== dateFilters.two.to)
            }
        },

        allTreeSegments () {
          let allSegments = JSON.parse((JSON.stringify(this.segmentsCustomDF)))
          allSegments.items = allSegments.items.concat(this.segmentsCatalogDF.items)
          return allSegments
        },

        metricsVisible () {
            let metrics = []

            this.tableCellsConfig.forEach(section => {
                section.items.forEach(metric => {
                    if (metric.visible) metrics.push(metric)
                })
            })

            return metrics
        },

        sortedData () {
            let sortKey = ''
            if (this.urlFilters.order_by) {
                sortKey = this.urlFilters.order_by
            } else {
                sortKey = this.dateFilters.comparison ? `count_all_one` : 'count_all'
            }

            let items = [...this.data.items].sort((a, b) => {
              if (this.urlFilters.order_direction === 'ASC') {
                return a[sortKey] - b[sortKey]
              } else {
                return b[sortKey] - a[sortKey]
              }
            })

            return {
              has_data: this.data.has_data,
              items: items,
              total: this.data.total
            }
        },

        viewPage () {
            return this.activeProjectData && Object.keys(this.activeProjectData).length !== 0
        },

        dateFiltersFormatted() {
            const {one, two} = this.dateFilters

            return {
                one: {
                    from: this.$moment(one.from, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE),
                    to: this.$moment(one.to, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE)
                },
                two: {
                    from: this.$moment(two.from, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE),
                    to: this.$moment(two.to, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE)
                }
            }
        },

        summaryItem () {
            return {
                ...this.summary.data,
                name: 'Итого'
            }
        },

        pageListing () {
            return this.page === 'listing'
        },

        devices() {
            return this.filtersDevices
        },

        regionSelected() {
            return this.getRegionSelected(this.urlFilters.region_id)
        },

        hasTags () {
            return this.tags && this.tags.length
        },

        tableCellsConfigKey() {
            return `${this.page}TableCellsConfig`
        },

        searchEntityParamName() {
            return this.$route.name === ROUTE_NAME.LISTINGS ? 'name_like' : 'url_like'
        }
    },

    watch: {
        async $route () {
            this.setCancelRequests(true)
            await this.initData()
        }
    },

    async created () {
        this.setCancelRequests(false)
        this.setTableCellsConfig()

        try {
            await this.getFilters({
                query: {
                    project_id: this.activeProjectId,
                    skip_regionality: 1
                }
            })
        } catch (_) {
        } finally {
            this.preloaderFilters = false
        }

        await this.getRegions()

        await this.initData()
    },

    destroyed () {
        this.setCancelRequests(true)
    },

    methods: {
        ...mapActions({
            getFilters: 'getFilters',
            getItems: 'dashboard/getItems'
        }),

        ...mapMutations({
            setCancelRequests: 'setCancelRequests',
            saveTableCellsConfig: 'tableSettings/saveTableCellsConfig'
        }),

        /* Изменение URL фильтров */
        updateFilters (filter, value) {
            this.updateUrl({ [filter]: value })
        },

        getColspan (cell) {
            const count = cell.items.reduce((prev, curr) => {
                if (curr.visible) {
                    prev++
                }
                return prev
            }, 0)

            return this.dateFilters.comparison ? count * 3 : count
        },

        async requestData (summary) {
            if (this.activeProjectId) {
              const {segments, catalog_ids} = getSegmentQueryParams(this.segmentsCustomDF, this.segmentsCatalogDF, this.urlFilters)

                let query = {
                    project_id: this.activeProjectId,
                    skip_regionality: 1,
                    types: this.urlFilters.types,
                    is_mobile: this.urlFilters.is_mobile === 0 ? '0' : this.urlFilters.is_mobile,
                    limit: this.urlFilters.limit,
                    offset: (this.urlFilters.page - 1) * this.urlFilters.limit,
                    catalog_ids,
                    segments,
                    order_by: this.urlFilters.order_by ? this.urlFilters.order_by : this.dateFilters.comparison ? 'sessions_one' : 'sessions',
                    order_direction: this.urlFilters.order_direction ? this.urlFilters.order_direction : 'DESC',
                    [this.searchEntityParamName]: this.urlFilters[this.searchEntityParamName],
                    search_engine: this.urlFilters.search_engine,
                  traffic_source: this.urlFilters.traffic_source,
                    summary
                }

                if (this.regionSelected) {
                    query.region_id = this.regionSelected.map(region => region.yandex_region_id).filter(id => id)
                    query.google_region_id = this.regionSelected.map(region => region.google_region_id)
                    query.need_geo = this.regionSelected && this.regionSelected.length ? 1 : undefined
                }

                for (let type of TRAFFIC_FILTER_TYPES) {
                    query[type] = this.urlFilters[type]
                    query[`traffic_conditions[${type}]`] = this.urlFilters[`traffic_conditions[${type}]`]
                }

                this.metricFiltersQuery.forEach(filter => {
                    if (this.urlFilters[filter]) {
                        query[filter] = this.urlFilters[filter]
                    }
                })

                if (this.dateFilters.comparison) {
                    query.period_one_date_from = this.dateFilters.one.from
                    query.period_one_date_to = this.dateFilters.one.to
                    query.period_two_date_from = this.dateFilters.two.from
                    query.period_two_date_to = this.dateFilters.two.to
                } else {
                    query.date_from = this.dateFilters.one.from
                    query.date_to = this.dateFilters.one.to
                }

                try {
                    await this.getItems({
                        query,
                        type: this.page
                    })
                } catch ({message}) {
                    this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
                }
            }
        },

        setTableCellsConfig () {
            let tableCellsConfig = localStorage.getItem(this.tableCellsConfigKey)

            if (tableCellsConfig) {
                this.saveTableCellsConfig({newConfig: JSON.parse(tableCellsConfig), key: this.tableCellsConfigKey})
            } else {
                let newConfig = {}
                this.tableCellsConfig.forEach(section => {
                    newConfig[section.id] = section.items.some(metric => metric.visible)
                    section.items.forEach(metric => {
                        newConfig[metric.id] = metric.visible
                    })
                })
                this.saveTableCellsConfig({newConfig, key: this.tableCellsConfigKey})
            }
        },

        async initData () {
            if (!this.preloaderTable) this.preloaderTable = true

            this.requestData(1)
            await this.requestData()

            this.preloaderTable = false
        },

        selectItem (id) {
            const idx = this.itemsSelected.indexOf(id)
            if (idx === -1) {
                this.itemsSelected.push(id)
            } else {
                this.itemsSelected.splice(idx, 1)
            }
        },

        clearItemsSelected () {
            this.itemsSelected = []
        },

        toggleAllItems (val) {
            if (val) {
                this.itemsSelected = this.data.items.map(item => item.df_url_id)
            } else {
                this.clearItemsSelected()
            }
        },

        sliceName () {
            this.slicedName ^= true
        }
    }
}
