<template>
  <div
    class="slice-btn"
    :class="{'_active': slicedName}"
    @click="sliceName"
  >
    <icon
        name="vertical_align_top"
        :fill="slicedName ? '#9D99AA' : '#2979FF'"
    />
  </div>
</template>

<script>
export default {
  props: {
    slicedName: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    sliceName () {
      this.$emit('slice-name')
    }
  }
}
</script>

<style lang="scss" scoped>
.slice-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  transform: rotate(90deg);
  margin-left: auto;

  &._active {
    transform: rotate(-90deg);
  }
}
</style>
