import constants from '@/const/'
export default {
  data () {
    return {
      projectWithAllWSTypes: constants.PROJECT_WITH_ALL_WS_TYPES
    }
  },
  computed: {
    filteredTableCellsConfig () {
      if (!this.tableCellsConfig) return []
      if (!this.activeProjectId || !this.shouldFilterWSMetrics) return this.tableCellsConfig
      return this.tableCellsConfig.map((el) => this.filterWSByProject(el))
    },

    shouldFilterWSMetrics () {
      return this.activeProjectId !== this.projectWithAllWSTypes
    },
    
    activeProjectId () {
      let id = this.$route.params.activeProject
      return id ? +id : undefined
    },
  },
  methods: {
    /* Скрытие метрик WS1, WS3 для всех проектов кроме 9082 */
    filterWSByProject (configItem) {
      const CONFIG_ITEM_TO_EDIT = 'demand'

      if (configItem.id !== CONFIG_ITEM_TO_EDIT) {
        return configItem
      } else {
        let DEMAND_ITEMS = [...configItem.items]
        DEMAND_ITEMS = DEMAND_ITEMS.filter((item) => !item.availableFor || (this.activeProjectId && item.availableFor === this.activeProjectId))
    
        return {
          ...configItem,
          items: DEMAND_ITEMS
        }
      }
    }
  }
}