<template>
  <vue-line>
    <vue-item :class="['entity__table-cell-name', `_${page}`]">
      <div class="entity__table-name-wrapper">
        <v-checkbox
            v-if="!summary"
            :value="checked"
            class="entity__table-checkbox"
            @input="$emit('select')"
        />
        <div
            v-if="$route.name === ROUTE_NAME.LISTINGS"
            class="listing__table-name"
        >
          <span class="listing__table-name-primary">{{ data.name }}</span>
          <a
              class="listing__table-name-secondary"
              :href="data.url_name"
              target="_blank"
          >
            {{ data.url_name }}
          </a>
        </div>
        <template v-else>
          <a
              v-if="data.url_name"
              class="card__table-name"
              :href="data.url_name"
              target="_blank"
          >
            {{ data.url_title | maxLength(47, slicedName) }}
          </a>

          <span v-else>{{ data.name }}</span>
        </template>
      </div>
    </vue-item>
    <template v-for="(metric, i) in metrics">
      <template v-if="comparison">
        <v-table-data-cell
            :key="`${i}-one`"
            :value="data[`${metric.id}_one`]"
            :is-listing="data.is_listing"
            :additional="metric.additional"
            :round-number-digits="metric.id === 'conversions' ? 2 : 0"
            :data-test="`table_cell_${metric.id}_one_${idx}`"
        />
        <v-table-data-cell
            :key="`${i}-two`"
            :value="data[`${metric.id}_two`]"
            :is-listing="data.is_listing"
            :additional="metric.additional"
            :round-number-digits="metric.id === 'conversions' ? 2 : 0"
            :data-test="`table_cell_${metric.id}_two_${idx}`"
        />
        <v-table-data-cell
            :key="`${i}-diff`"
            :value="data[`${metric.id}_diff`]"
            :is-listing="data.is_listing"
            :additional="metric.additional"
            :round-number-digits="metric.id === 'conversions' ? 2 : 0"
            diff
            :diff-prc="getDiffPrc(metric)"
        />
      </template>

      <v-table-data-cell
          v-else
          :key="`${i}-value`"
          :value="data[metric.id]"
          :is-listing="data.is_listing"
          :additional="metric.additional"
          :round-number-digits="metric.id === 'conversions' ? 2 : 0"
          :data-test="`table_cell_${metric.id}_${idx}`"
      />
    </template>
  </vue-line>
</template>

<script>
import {roundNumber, hmsToSeconds } from '@/function'
import ROUTE_NAME from '@/router/routeName'

import tableDataCell from '@/services/components/tableDataCell.vue'

export default {
  name: 'tableRow',

  components: {
    'v-table-data-cell': tableDataCell
  },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    metrics: {
      type: Array,
      default: () => ([])
    },

    comparison: {
      type: Boolean,
      default: false
    },

    idx: {
      type: [Number, String],
      default: 0
    },

    checked: {
      type: Boolean,
      default: false
    },

    summary: {
      type: Boolean,
      default: false
    },

    slicedName: {
      type: Boolean,
      default: false
    },

    page: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      ROUTE_NAME
    }
  },

  methods: {
    roundNumber,

    getDiffPrc (metric) {
      const diff = this.getValue(this.data[`${metric.id}_diff`])
      const one = this.getValue(this.data[`${metric.id}_one`])
      const two = this.getValue(this.data[`${metric.id}_two`])

      return metric.additional || diff === undefined
          ? undefined
          : diff === 0
              ? 0
              : diff === one
                  ? 100
                  : roundNumber(diff * 100 / two, 2)
    },

    getValue (val) {
      return typeof val === 'string' ? hmsToSeconds(val) : val
    }
  }
}
</script>

<style scoped lang="scss" src="../../entityContainer.scss"></style>
